import React, { Component } from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import AOS from 'aos'
import SEO from '../components/seo'

class PageTemplate extends Component {
  componentDidMount() {
    AOS.init({
      startEvent: 'load',
    })
  }

  render() {
    const currentPage = this.props.data.wordpressPage

    return (
      <Layout>
        <SEO
          title={
            currentPage.title +
            ' | Kuchnie na wymiar Śląsk, Małopolska | Meble kuchenne Kraków'
          }
          keywords={[`gatsby`, `application`, `react`]}
        />
        <h1 dangerouslySetInnerHTML={{ __html: currentPage.date }} />
        <div dangerouslySetInnerHTML={{ __html: currentPage.content }} />
      </Layout>
    )
  }
}

export default PageTemplate

export const pageQuery = graphql`
  query($id: String) {
    wordpressPage(id: { eq: $id }) {
      title
      content
      date(formatString: "MMMM DD, YYYY")
    }
    site {
      id
      siteMetadata {
        title
      }
    }
  }
`
